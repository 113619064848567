import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { AppAbstract } from 'src/app/app.abstract';
import { FormaPagamentoEnum } from 'src/app/enuns/forma-pagamento.enum';
import { DadosInterface } from 'src/app/models/dados.interface';
import { BancoEnum } from '../../../enuns/banco.enum';
import { ImagemComponent } from '../../../modals/imagem/imagem.component';

@Component({
  selector: 'ftc-debito-conta',
  templateUrl: './debito-conta.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    NgFor,
    NgIf,
    NgxMaskModule,
    ImagemComponent,
  ],
})
export class DebitoContaComponent extends AppAbstract implements OnInit {
  @Output() debitOutput = new EventEmitter();

  dados: DadosInterface = {} as DadosInterface;

  BancoEnum: typeof BancoEnum = BancoEnum;

  formDebito!: FormGroup;

  openModalImage: boolean = false;
  submetido: boolean = false;

  bankSelected: string = '';

  listaBancos: any[] = [];
  listaCodigosBancarios: any[] = [];
  listaDiasNumeral: any[] = [];

  ngOnInit(): void {
    this.dados = this._localStorageService.descriptografarDados();
    this.criarFormulario();
  }

  private criarFormulario(): void {
    this.formDebito = this._formBuilder.group({
      banco: [this.dados.pagamento?.banco?.bancoId || '', Validators.required],
      codigo: [this.dados.pagamento?.banco?.codigoOperacao || ''],
      dia: [this.dados.pagamento?.diaPagamento || '', Validators.required],
      agencia: [this.dados.pagamento?.banco?.agencia || '', Validators.required],
      conta: [this.dados.pagamento?.banco?.conta || '', Validators.required],
      digito: [this.dados.pagamento?.banco?.digitoConta || '', Validators.required],
    });

    this.getListas();
  }

  private async getListas(): Promise<void> {
    await this._apiService
      .get(this._urlService.listaBancos)
      .subscribe((data: any) => (this.listaBancos = data.bancos));

    await this._apiService
      .get(this._urlService.listaCodigosBancarios)
      .subscribe((data: any) => (this.listaCodigosBancarios = data.codigos_bancarios));

    await this._apiService
      .get(this._urlService.listaDiasNumeral)
      .subscribe((data: any) => (this.listaDiasNumeral = data.diasPagamento));
  }

  protected togglePagamento(value: FormaPagamentoEnum): void {
    this._pagamentoService.definirPagamento(value);
  }

  protected selecionarBanco(): void {
    this.bankSelected = this.formDebito.value.banco;
  }

  protected comoUsar(): void {
    this.openModalImage = true;
  }

  protected fecharModal(): void {
    this.openModalImage = false;
  }

  protected continuar(): void {
    this.submetido = true;
    if (this.formDebito.valid) {
      this.criptografarDebito();
      this._logService.postLog();
    }
  }

  private criptografarDebito(): void {
    this.dados.pagamento = {
      ...this.dados.pagamento,
      banco: {
        bancoNome: this._debitoService.getBancoNome(this.formDebito.value.banco),
        bancoId: this.formDebito.value.banco,
        codigoOperacao: this.formDebito.value.codigo ?? '',
        agencia: this.formDebito.value.agencia,
        conta: this.formDebito.value.conta,
        digitoConta: this.formDebito.value.digito.toLowerCase(),
      },
      diaPagamento: this.formDebito.value.dia,
    };

    this._localStorageService.criptografarDados(this.dados);
    this.debitOutput.emit();
  }

  get f(): { [key: string]: any } {
    return this.formDebito.controls;
  }
}
