import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WhatsappBtnComponent } from 'src/app/components/whatsapp-btn/whatsapp-btn.component';
import { IconSvgComponent } from '../../components/icon-svg/icon-svg.component';

@Component({
  selector: 'ftc-atencao',
  templateUrl: './atencao.component.html',
  standalone: true,
  imports: [IconSvgComponent, NgIf, WhatsappBtnComponent],
})
export class AtencaoComponent {
  /* Legenda
    1 = CPF já é cliente
    2 = conclua por telefone
    3 = CPF reprovado
    4 = pedido em analise
    5 = dispositivo já ativo
    6 = erro generico
    7 = dispositivo não encontrado
  */

  @Input() type: number = 0;

  @Output() fecharModal = new EventEmitter();

  protected modalfechar(): void {
    this.fecharModal.emit();
  }
}
